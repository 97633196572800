<template>
  <div class="video-content" ref="largeVideoRef">
    <video
      id="homeVideo"
      class="home-video"
      autoplay
      ref="largeVideo"
      controls
      @ended="closeVideo"
    >
      <source :src="videoSrc" type="video/mp4" />
    </video>

    <!-- <div class="video-controls">

    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    videoSrc: String,
  },
  data() {
    return {
      socket: "",
    };
  },
  created() {
    this.initWs();
  },
  mounted() {
    // this.$nextTick(() => {
    this.$refs.largeVideo.muted = false;
    // this.$refs.largeVideo.play();
    // });
  },
  methods: {
    initWs() {
      let wsUrl = process.env.VUE_APP_BASE_WSURL;
      this.socket = new WebSocket(wsUrl);
    },
    wsSend(data) {
      this.socket.send(data);
    },
    closeVideo() {
      this.$parent.isShowVideo = false;
      let message = {
        scene: [],
        deactive: "videoOver",
      };
      this.wsSend(JSON.stringify(message));
    },
    pauseVideo() {
      this.$refs.largeVideo.pause();
    },
    playVideo() {
      this.$refs.largeVideo.play();
    },
  },
};
</script>

<style>
.video-content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  pointer-events: all;
}
.home-video {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
  animation: scales 0.5s forwards;
}
@keyframes scales {
  from {
    transform: scale(0.1);
  }
  to {
    transform: scale(1);
  }
}
</style>
